import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import reactStringReplace from 'react-string-replace';
import { MAX_PAYLOAD_SIZE } from '../../../../constants';
import useOnChangeValue from '../../../../customHooks/useOnChangeValue';
import { uploadFilePresignedUrl } from '../../../../services/api/aws';
import { getPresignedUrlVehiclesConsumptions } from '../../../../services/api/vehicleConsumptions';
import Button from '../../../ui/button/Button';
import ErrorList from '../../../ui/errorList/ErrorList';
import FormButtonSection from '../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementFull from '../../../ui/formComponents/formElementFull/FormElementFull';
import FormHeader from '../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../ui/formComponents/formWrapper/FormWrapper';
import InputFileSingle from '../../../ui/formComponents/inputFile/InputFileSingle';
import Modal from '../../../ui/modal/Modal';
import ErrorLabel from '../../../ui/statusLabels/errorLabel/ErrorLabel';
import './styles.scss';

type FormData = {
  file: File | null;
  errors: ErrorType[];
};

type Props = {
  uploadConsumptions: () => void;
};

const UploadConsumptions = ({ uploadConsumptions }: Props) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    file: null,
    errors: []
  });

  const [requestErrors, setRequestErrors] = useState<UploadFileBulkError[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);

  const [showErrors, setShowErrors] = useState(false);

  const handleShowErrors = () => setShowErrors(true);
  const handleHideErrors = () => setShowErrors(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleUploadFile = async () => {
    if (!formData.file) {
      setFormData((prev) => ({
        ...prev,
        errors: [
          {
            error: 'file',
            description: t('error.fileRequired')
          }
        ]
      }));
      return;
    }
    setRequestErrors([]);
    const fileSize = formData.file.size / (1024 * 1024);

    if (fileSize >= MAX_PAYLOAD_SIZE) {
      setFormData((prev) => ({
        ...prev,
        errors: [
          {
            error: 'file',
            description: t('error.maxPayloadSize')
          }
        ]
      }));
      return;
    }

    setLoadingButton(true);

    const data = await getPresignedUrlVehiclesConsumptions({
      file_name: formData.file?.name ?? 'vehicle_consumption.csv'
    });

    const response = await uploadFilePresignedUrl(formData.file, data.upload_url);

    if (!response) {
      setLoadingButton(false);
      return;
    }
    if ('invalid_csv_file' in data) {
      setRequestErrors(data.invalid_csv_file);
      setLoadingButton(false);
      return;
    }

    setLoadingButton(false);
    uploadConsumptions();
  };

  const downloadFile = () => {
    // Download the csv file in public/files/vehicleConsumption/vehicle_consumptions_template_Dcycle.csv
    window.open('/files/vehicleConsumption/vehicle_consumptions_template_Dcycle.csv', '_blank');
  };

  const renderFileLabelTop = () => {
    const text = t('vehicles.fileLabelTop');
    const replacements = [
      {
        search: '{{templates}}',
        component: (
          <span className='highlight-text-color pointer' onClick={downloadFile}>
            {t('vehicles.exampleTemplates')}
          </span>
        )
      }
    ];

    let replacedText: string | React.ReactNode = text;

    replacements.forEach(({ search, component }) => {
      replacedText = reactStringReplace(replacedText as string, search, () => component);
    });

    return replacedText;
  };
  return (
    <div className='upload-consumptions'>
      <FormHeader
        title={t('vehicles.uploadConsumptions')}
        description={t('vehicles.uploadConsumptionsDescription')}
      />
      <FormWrapper>
        <FormElementFull>
          <p className='file-label body1-bold-font'>{renderFileLabelTop()}</p>
          <InputFileSingle
            handleFile={onChangeValue('file')}
            buttonText={t('vehicles.uploadCsvFile')}
            labelText={t('vehicles.uploadCsvFileLabel')}
            fileName={formData.file?.name ?? ''}
            allowedTypes={['text/csv']}
            error={formData.errors.find((error) => error.error === 'file')}
          />
        </FormElementFull>
      </FormWrapper>
      <FormButtonSection>
        <Button
          lookAndFeel='primary'
          text={t('vehicles.save')}
          size='medium'
          onClick={handleUploadFile}
          loading={loadingButton}
        />
      </FormButtonSection>
      {requestErrors.length > 0 && (
        <div className='error-wrapper'>
          <ErrorLabel onClick={handleShowErrors}>
            {t('error.rowsWithErrors', { rowNumber: requestErrors.length })}
          </ErrorLabel>
        </div>
      )}

      <Modal show={showErrors} onClose={handleHideErrors} width='600px'>
        <ErrorList
          data={requestErrors}
          columns={[
            {
              title: 'license_plate',
              dataIndex: 'license_plate',
              key: 'license_plate'
            },
            {
              title: 'custom_id',
              dataIndex: 'custom_id',
              key: 'custom_id'
            },
            {
              title: 'quantity',
              dataIndex: 'quantity',
              key: 'quantity'
            },
            {
              title: t('error.rowNumber'),
              dataIndex: 'row_index',
              key: 'row_index'
            },
            {
              title: 'Error',
              dataIndex: 'error',
              key: 'error'
            }
          ]}
        />
      </Modal>
    </div>
  );
};

export default UploadConsumptions;
