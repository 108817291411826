import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../../types/entities/user';
import { ConsumptionType } from '../../../types/entities/vehicleConsumption';
import apiFetch from '../../../utils/apiFetch';
import Button from '../../ui/button/Button';
import './styles.scss';
import FormHeader from '../../ui/formComponents/formHeader/FormHeader';
import FormButtonSection from '../../ui/formComponents/formButtonSection/FormButtonSection';

type Props = {
  deleteConsumption: (id: string) => void;
  consumptionToDelete: ConsumptionType;
  user: User | null;
};
function DeleteConsumption({ user, consumptionToDelete, deleteConsumption }: Props) {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] = useState(false);

  function submitForm(e: { key: string; target: any }) {
    if (
      e.key === 'Enter' &&
      e.target?.id !== 'select-hidden' &&
      e.target?.id !== 'select-search' &&
      !loadingButton
    ) {
      handleDeleteConsumption();
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', submitForm);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', submitForm);
    };
  });

  const handleDeleteConsumption = async () => {
    setLoadingButton(true);
    await apiFetch('DELETE', `/vehicle_consumptions/${consumptionToDelete.id}`, null, {
      'x-organization-id': user?.selectedOrganization
    });
    deleteConsumption(consumptionToDelete.id);

    setLoadingButton(false);
  };

  return (
    <div className='delete-modal'>
      <FormHeader
        title={t('vehicleDetail.deleteConsumption')}
        description={t('vehicleDetail.areYouSureDelete')}
      />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('vehicleDetail.delete')}
          onClick={handleDeleteConsumption}
          loading={loadingButton}
        />
      </FormButtonSection>
    </div>
  );
}

export default DeleteConsumption;
