import apiFetch from '../../utils/apiFetch';

type VehicleConsumptionTemplateBody = {
  file_name: string;
};

export const getPresignedUrlVehiclesConsumptions = async (body: VehicleConsumptionTemplateBody) => {
  try {
    const response = await apiFetch('POST', `/vehicle_consumptions/bulk/csv`, body);
    return response.data;
  } catch (err) {
    return err;
  }
};

export const deleteVehicleConsumptionFromOrganization = async (
  org_id: string,
  status: string,
  startDate?: string,
  endDate?: string
) => {
  try {
    type QueryParams = {
      status?: string;
      startDate?: string;
      endDate?: string;
    };
    const queryParams: QueryParams = {};
    if (status && status !== 'all') {
      queryParams['status'] = status;
    }
    if (startDate && endDate) {
      queryParams['startDate'] = startDate;
      queryParams['endDate'] = endDate;
    }
    const response = await apiFetch(
      'DELETE',
      `/vehicle_consumptions/organizations/${org_id}`,
      null,
      {
        'x-organization-id': org_id
      },
      queryParams
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getVehicleConsumptionByOrgIdStatus = async (org_id: string, status = 'error') => {
  try {
    const response = await apiFetch(
      'GET',
      `/vehicle_consumptions/organizations/${org_id}/template`,
      null,
      {
        'x-organization-id': org_id
      },
      { status }
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

type UpdateBody = {
  quantity: number;
  unit_id: string;
  start_date: Date;
  end_date: Date;
  organization_id: string;
  custom_id: string;
};
export const updateVehicleConsumption = async (id: string, body: UpdateBody) => {
  try {
    const response = await apiFetch('PATCH', `/vehicle_consumptions/${id}`, body, {
      'x-organization-id': body.organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

type CreateBody = {
  quantity: number;
  unit_id: string;
  start_date: Date;
  end_date: Date;
  vehicle_id: string;
  organization_id: string;
  custom_id: string;
};
export const createVehicleConsumption = async (body: CreateBody) => {
  try {
    const response = await apiFetch('POST', `/vehicle_consumptions`, body, {
      'x-organization-id': body.organization_id
    });
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getVehicleConsumptionById = async (id: string) => {
  try {
    const response = await apiFetch('GET', `/vehicle_consumptions/${id}`);
    return response.data;
  } catch (err) {
    return null;
  }
};

// Get list paginated all vehicle consumptions
export const getVehicleConsumptionsPaginated = async (
  vehicleId: string,
  page: number,
  size: number,
  filterBy?: string | null,
  sortBy?: string | null
) => {
  try {
    const urlParams = new URLSearchParams();
    urlParams.append('page', page.toString());
    urlParams.append('size', size.toString());
    if (filterBy) urlParams.append('filter_by', filterBy.toString());
    if (sortBy) urlParams.append('sort_by', sortBy.toString());

    const queryParams = {
      page,
      size,
      filter_by: filterBy,
      sort_by: sortBy
    };

    const response = await apiFetch(
      'GET',
      `/vehicle_consumptions/vehicle/${vehicleId}`,
      null,
      null,
      queryParams
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
