import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setNotification } from '../../../actions/notification';
import { SIZE_PAGINATION_SMALL } from '../../../constants';
import { UserContext } from '../../../context/userContext';
import useDebounceValue from '../../../customHooks/useDebounceValue';
import { useFeatureFlags } from '../../../customHooks/useFeatureFlags';
import useSelectedOrganization from '../../../customHooks/useSelectedOrganization';
import { getUnits } from '../../../services/api/unit';
import { getVehicle, updateVehicle } from '../../../services/api/vehicle';
import {
  getVehicleConsumptionById,
  getVehicleConsumptionsPaginated
} from '../../../services/api/vehicleConsumptions';
import { Vehicle } from '../../../types/entities/vehicle';
import { ConsumptionType } from '../../../types/entities/vehicleConsumption';
import { InputSize } from '../../../types/utilsEnums/input';
import apiFetch from '../../../utils/apiFetch';
import formatNumber from '../../../utils/formatNumber';
import { numberToDecimalNonZero } from '../../../utils/numberToDecimal';
import { FiltersContext } from '../../layout/Filters/FilterContext';
import FilterSection, { FilterOptionType } from '../../layout/Filters/FilterSection';
import Filters from '../../layout/Filters/Filters';
import FiltersDate from '../../layout/Filters/FiltersDate';
import FiltersHandlers from '../../layout/Filters/FiltersHandlers';
import useFilters from '../../layout/Filters/hooks/useFilters';
import Breadcrumb from '../../layout/breadcrumb/Breadcrumb';
import Button from '../../ui/button/Button';
import ButtonDropdown from '../../ui/buttonDropdown/ButtonDropdown';
import FormText from '../../ui/formComponents2/formInputs/formText/FormText';
import Icon from '../../ui/icon/Icon';
import InfiniteList from '../../ui/infiniteList/InfiniteListV2';
import useFetchInfiniteList from '../../ui/infiniteList/hooks/useFetchInfiniteList';
import Modal from '../../ui/modal/Modal';
import Sorting from '../../ui/sorting/Sorting';
import useOnSort from '../../ui/sorting/hooks/useOnSort';
import ErrorLabel from '../../ui/statusLabels/errorLabel/ErrorLabel';
import PendingLabel from '../../ui/statusLabels/pendingLabel/PendingLabel';
import SuccessLabel from '../../ui/statusLabels/successLabel/SuccessLabel';
import TooltipWrapper from '../../ui/tooltip/TooltipWrapper';
import Validation, { ValidationCategory } from '../../validation/Validation';
import DeleteConsumption from './DeleteConsumption';
import EditCreateConsumption from './EditCreateConsumption';
import useColumns from './hooks/useColumns';
import useSortingOptions from './hooks/useSortingOptions';
import LimitCompleted from './limitCompleted/LimitCompleted';
import './styles.scss';
import { generateQueryParamsFromObject, getUrl } from '../../../utils/url';
import { CategoriesUploadedFiles } from '../uploadedFiles/constants';
import { ROUTES } from '../../../constants/routes';
import TotalLegendV2 from '../../ui/totalLegend/TotalLegendV2';

interface RestPagination {
  total_error: number;
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  setNotification
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

function VehicleDetail({ setNotification }: Props) {
  const { t, i18n } = useTranslation();
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const params = useParams();
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const selectedOrganization = useSelectedOrganization();
  const flags = useFeatureFlags();

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortingOptions = useSortingOptions();

  const defaultSort = {
    ...sortingOptions[3],
    direction: 'desc' as 'desc' | 'asc'
  };

  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [vehicle, setVehicle] = useState<Vehicle>();
  const [showNewConsumption, setShowNewConsumption] = useState(false);
  const [consumptionToEdit, setConsumptionToEdit] = useState<ConsumptionType>();
  const [consumptionToDelete, setConsumptionToDelete] = useState<ConsumptionType>();
  const [units, setUnits] = useState<Unit[]>([]);
  const [sideFilters, setFilters] = useState<FilterOptionType[]>([]);
  const [open, setOpen] = useState(false);
  const [remove, setRemoveFilters] = useState(false);
  const [dates, setDates] = useState<{ startDate: string; endDate: string } | undefined>();
  const [clearDates, setClearDates] = useState(false);
  const [url, setUrl] = useState(
    `/vehicle_consumptions/vehicle/${params.id}?sort_by=${defaultSort.id}:${defaultSort.direction}`
  );
  const [showValidationModal, setShowValidationModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showLimitCompleted, setShowLimitCompleted] = useState(false);

  const debounceValue = useDebounceValue(searchValue);

  const fetch = async (page: number): Promise<Pagination<ConsumptionType> | undefined> => {
    if (!params?.id) return;

    const urlSearchParams = new URLSearchParams(url.split('?')[1]);
    const filterBy = urlSearchParams.get('filter_by');
    const sortBy = urlSearchParams.get('sort_by');

    const response = await getVehicleConsumptionsPaginated(
      params?.id,
      page,
      SIZE_PAGINATION_SMALL,
      filterBy,
      sortBy
    );
    if (response?.response?.status >= 400) return;
    return response;
  };

  const {
    fetchData,
    firstLoading,
    loading: loadingTable,
    total,
    addElement,
    removeElement,
    editElement,
    data,
    rest
  } = useFetchInfiniteList<ConsumptionType, RestPagination>(fetch, [url]);

  const fetchDataVehicle = async () => {
    if (!params.id || !user?.selectedOrganization) {
      return;
    }
    const vehicleDetail = await getVehicle(params.id, user?.selectedOrganization);

    let name = vehicleDetail.name || '';

    if (vehicleDetail.unknown_vehicle && i18n.exists(`vehicles.${vehicleDetail.name}`)) {
      name = `${t(`vehicles.${vehicleDetail.name}`)}`;
    }
    setVehicle({
      ...vehicleDetail,
      name
    });
    const units = await getUnits();
    const allowedUnits = units.filter(
      (unit: { name: string }) => unit.name === 'litre_(l)' || unit.name === 'kilometer_(km)'
    );
    setUnits(allowedUnits);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchDataVehicle();
      setLoading(false);
    };

    fetchData();
  }, [params.id]);

  useFilters({
    setUrl,
    url,
    setFilters,
    filters: sideFilters,
    removeFilters: setRemoveFilters,
    remove,
    dates,
    setDates,
    setClearDates,
    searchFilters: [
      {
        key: 'custom_id',
        value: debounceValue,
        setValue: setSearchValue
      }
    ]
  });

  const fetchConsumptionToEdit = async (id: string) => {
    if (!id) return;
    const data = await getVehicleConsumptionById(id);
    setConsumptionToEdit(data);
  };

  const fetchConsumptionToDelete = async (id: string) => {
    if (!id) return;
    const data = await getVehicleConsumptionById(id);
    setConsumptionToDelete(data);
  };

  useEffect(() => {
    setShowValidationModal(false);
    // Extract the query parameter you want to monitor
    const queryParams = new URLSearchParams(location.search);
    const myQueryParam = queryParams.get('show');

    if (myQueryParam === 'create') setShowNewConsumption(true);
    if (myQueryParam === 'edit') {
      fetchConsumptionToEdit(queryParams.get('id') || '');
    }
    if (myQueryParam === 'delete') {
      fetchConsumptionToDelete(queryParams.get('id') || '');
    }
  }, [location.search]);

  const renderDropdownComponent = (consumption: ConsumptionType) => {
    if (vehicle?.status === 'archived') {
      return (
        <TooltipWrapper
          text={t('vehicleDetail.archivedVehicleTooltip')}
          style={{ marginLeft: 'auto' }}>
          <Icon icon='locked' color={'gradient'} />
        </TooltipWrapper>
      );
    }

    const options = [
      {
        id: `${consumption.id}-edit`,
        name: t('vehicleDetail.edit'),
        onClick: () => {
          setConsumptionToEdit(consumption);
        }
      },
      {
        id: `${consumption.id}-delete`,
        name: t('vehicleDetail.delete'),
        onClick: () => {
          setConsumptionToDelete(consumption);
        }
      }
    ];

    if (consumption.file_name) {
      options.push({
        id: `${consumption.id}-goToFile`,
        name: t('vehicleDetail.goToFile'),
        onClick: () => {
          navigate(
            getUrl(
              `${ROUTES.MEASURE_UPLOADED_FILES}/${CategoriesUploadedFiles.VEHICLE_CONSUMPTIONS}`,
              {
                queryParams: generateQueryParamsFromObject({
                  name: consumption.file_name as string
                })
              }
            )
          );
        }
      });
    }

    return <ButtonDropdown options={options} />;
  };

  const renderCustomIdComponent = (custom_id: string) => {
    return (
      <div className='icon-text-wrapper'>
        <img src='/images/icons/cloudGray.svg' alt='cloud' />
        <span>{custom_id}</span>
      </div>
    );
  };

  const { id } = useParams();

  const onCloseModal = () => {
    setShowNewConsumption(false);
    setConsumptionToEdit(undefined);
    setConsumptionToDelete(undefined);
    // delete query param "show"
    searchParams.delete('show');
    setSearchParams(searchParams);
  };

  const handleShowNewConsumption = () => {
    setShowNewConsumption(true);
  };

  const addConsumption = (consumption: ConsumptionType) => {
    const newConsumption = {
      id: consumption.id,
      quantity: consumption.quantity,
      unit: consumption.unit,
      start_date: consumption.start_date,
      end_date: consumption.end_date,
      status: 'active',
      custom_id: consumption.custom_id,
      co2e: undefined
    };

    addElement(newConsumption);
    onCloseModal();
    setNotification(t('notification.createConsumption'));
  };

  const editConsumption = (consumption: ConsumptionType) => {
    editElement(consumption);
    setConsumptionToEdit(undefined);
    onCloseModal();
    setNotification(t('notification.editConsumption'));
  };

  const deleteConsumption = (id: string) => {
    removeElement(id);
    setNotification(t('notification.deleteConsumption'));
    setConsumptionToDelete(undefined);
  };

  const statusIconSelect = (status: string) => {
    const statusMap: { [key: string]: ReactNode } = {
      active: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
      success: <SuccessLabel>{t('general.completed')}</SuccessLabel>,
      loading: (
        <TooltipWrapper text={t('vehicleDetail.processingTooltip')} position='top'>
          <PendingLabel>{t('general.processing')}</PendingLabel>
        </TooltipWrapper>
      ),
      error: <ErrorLabel>{t('general.withErrors')}</ErrorLabel>
    };
    return statusMap[status] ?? null;
  };

  const parseData = (consumptionsFiltered: ConsumptionType[]) => {
    return consumptionsFiltered.map((consumption) => {
      const consumptionStartDate = consumption.start_date
        ? new Date(consumption.start_date).toLocaleDateString()
        : '-';
      const consumptionEndDate = consumption.end_date
        ? new Date(consumption.end_date).toLocaleDateString()
        : '-';
      return {
        ...consumption,
        custom_id: renderCustomIdComponent(consumption.custom_id ?? '-'),
        quantity: (
          <span>
            {formatNumber(consumption.quantity)} {t(`units_short.${consumption.unit.name}`)}
          </span>
        ),
        co2e:
          consumption.status === 'loading' || !consumption.co2e ? (
            <TooltipWrapper text={t('vehicleDetail.co2eTooltip')}>
              <span className='highlight-text-color' style={{ fontWeight: 600 }}>
                -
              </span>
            </TooltipWrapper>
          ) : (
            <span
              className='highlight-text-color'
              style={{ fontWeight: 600, textAlign: 'right', display: 'block' }}>
              {formatNumber(numberToDecimalNonZero(consumption.co2e))} kg
            </span>
          ),
        startDate: consumptionStartDate,
        endDate: consumptionEndDate,
        edit: renderDropdownComponent(consumption),
        status: statusIconSelect(consumption.status)
      };
    });
  };

  const columns = useColumns();

  const sideBarFilters = [
    {
      options: units.map((unit) => ({
        id: unit.id,
        name: t(`units.${unit.name}`),
        total: undefined,
        type: 'unit_id'
      })),
      title: t('templates.vehicles_consumptions.unit_measure'),
      elToShow: 0
    }
  ];

  const onSort = useOnSort({ url, setUrl });

  const handleDearchiveVehicle = async () => {
    setLoadingButton(true);

    const responseVehicles = await apiFetch(
      'GET',
      '/vehicles',
      undefined,
      {},
      {
        page: 1,
        size: SIZE_PAGINATION_SMALL,
        filter_by: 'status:eqactive'
      }
    );
    if (!responseVehicles || !responseVehicles.data) {
      setLoadingButton(false);
      return;
    }
    if (
      selectedOrganization?.limit_vehicles &&
      selectedOrganization?.limit_vehicles <= responseVehicles.data.total
    ) {
      setLoadingButton(false);
      setShowLimitCompleted(true);
      return;
    }
    if (!vehicle) {
      setLoadingButton(false);
      return;
    }
    const response = await updateVehicle(vehicle.id, { status: 'active' });
    setLoadingButton(false);
    if (!response) return;
    setVehicle({
      ...vehicle,
      status: 'active'
    });
    setNotification(t('notification.dearchiveVehicle'));
  };

  if (!vehicle) return null;

  return (
    <section className='vehicle-detail'>
      <FiltersContext.Provider
        value={{
          setFilters,
          filters: sideFilters,
          setOpen,
          open,
          removeFilters: setRemoveFilters,
          remove,
          setDates,
          dates,
          setClearDates,
          clearDates
        }}>
        <div className='vehicle-detail__header page-header'>
          <h3 className='headline3-font on-light-text-color'>{t('measureMain.title')}</h3>
          <Breadcrumb crumbsReplace={id ? [{ key: id, value: vehicle.name }] : []} />
        </div>
        <div className='vehicle-detail__body main-bg-color solid-border '>
          <div className='vehicle-detail-card main-bg-color solid-border '>
            <h1 className='headline4-font'>
              {vehicle.status !== 'archived'
                ? t('vehicleDetail.start')
                : t('vehicleDetail.archivedVehicle')}
            </h1>
            <p className='subtitle3-font'>
              {vehicle.status !== 'archived'
                ? t('vehicleDetail.startDescription')
                : t('vehicleDetail.archivedVehicleDescription')}
            </p>

            <div className='buttons'>
              {vehicle.status !== 'archived' ? (
                <Button
                  lookAndFeel='primary'
                  text={t('vehicleDetail.addConsumption')}
                  size='small'
                  onClick={handleShowNewConsumption}
                  disabled={loading}
                />
              ) : (
                <Button
                  lookAndFeel='primary'
                  text={t('vehicleDetail.dearchiveVehicle')}
                  iconNode={<Icon icon='locked' color={'white'} />}
                  size='small'
                  onClick={handleDearchiveVehicle}
                  disabled={loading}
                  loading={loadingButton}
                />
              )}
            </div>
          </div>
        </div>
        <Filters>
          <FiltersDate />
          {sideBarFilters?.map(
            (section, idx) =>
              section.options?.length > 0 && (
                <FilterSection
                  options={section.options}
                  title={section.title}
                  elToShow={section.elToShow}
                  key={`section-${idx}`}
                />
              )
          )}
        </Filters>

        <InfiniteList
          data={parseData(data)}
          columns={columns}
          loading={loadingTable}
          firstLoading={firstLoading}
          fetchData={fetchData}
          total={total}
          header={
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <FormText
                    icon={'/images/icons/search.svg'}
                    placeholder={t(`vehicleDetail.inputFilter`)}
                    onChange={(e: { target: { value: string } }) => {
                      setSearchValue(e.target.value);
                    }}
                    value={searchValue}
                    size={InputSize.SMALL}
                  />
                  <Sorting
                    options={sortingOptions}
                    label={t('input.sorting.orderBy')}
                    placeholder={t('input.placeholderSelect')}
                    onSort={onSort}
                    defaultSort={defaultSort}
                  />
                  <FiltersHandlers />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  {flags?.dataValidation && vehicle.status !== 'archived' ? (
                    <Button
                      lookAndFeel='primary'
                      text={t('validation.validate')}
                      size='small'
                      onClick={() => setShowValidationModal(true)}
                    />
                  ) : null}
                  <TotalLegendV2
                    totalLabel={tGeneral('total')}
                    totalElements={[
                      {
                        value: total,
                        label: t('vehicleDetail.total')
                      },
                      {
                        value: rest?.total_error ?? 0,
                        label: t('vehicleDetail.total2')
                      }
                    ]}
                  />
                </div>
              </div>
            </>
          }
        />
      </FiltersContext.Provider>

      <Modal show={showNewConsumption} onClose={onCloseModal} width='630px' maxWidth='630px'>
        <EditCreateConsumption
          user={user}
          mode='create'
          addConsumption={addConsumption}
          vehicleMotorType={vehicle?.known_vehicle?.vehicle_motor_type ?? ''}
        />
      </Modal>

      <Modal show={!!consumptionToEdit} onClose={onCloseModal} width='630px' maxWidth='630px'>
        <EditCreateConsumption
          user={user}
          mode='edit'
          consumptionToEdit={consumptionToEdit}
          editConsumption={editConsumption}
          vehicleMotorType={vehicle?.known_vehicle?.vehicle_motor_type ?? ''}
        />
      </Modal>

      <Modal show={!!consumptionToDelete} onClose={onCloseModal} width='428px' maxWidth='428px'>
        {consumptionToDelete && (
          <DeleteConsumption
            user={user}
            deleteConsumption={deleteConsumption}
            consumptionToDelete={consumptionToDelete}
          />
        )}
      </Modal>
      <Modal
        show={showValidationModal}
        onClose={() => setShowValidationModal(false)}
        width='900px'
        maxWidth='900px'>
        <Validation
          category={ValidationCategory.VEHICLES}
          subcategories={[]}
          entity={vehicle.name}
          entityId={params.id ?? ''}
        />
      </Modal>
      <Modal
        show={showLimitCompleted}
        onClose={() => setShowLimitCompleted(false)}
        maxWidth='484px'>
        <LimitCompleted handleClose={() => setShowLimitCompleted(false)} />
      </Modal>
    </section>
  );
}

export default connector(VehicleDetail);
